import request from '@/utils/request'


// 查询用户注销列表
export function listCancel(query) {
  return request({
    url: '/biz/user-cancels/list',
    method: 'get',
    params: query
  })
}

// 查询用户注销分页
export function pageCancel(query) {
  return request({
    url: '/biz/user-cancels/page',
    method: 'get',
    params: query
  })
}

// 查询用户注销详细
export function getCancel(data) {
  return request({
    url: '/biz/user-cancels/detail',
    method: 'get',
    params: data
  })
}

// 新增用户注销
export function addCancel(data) {
  return request({
    url: '/biz/user-cancels/add',
    method: 'post',
    data: data
  })
}

// 修改用户注销
export function updateCancel(data) {
  return request({
    url: '/biz/user-cancels/edit',
    method: 'post',
    data: data
  })
}

// 删除用户注销
export function delCancel(data) {
  return request({
    url: '/biz/user-cancels/delete',
    method: 'post',
    data: data
  })
}
